/* global Swiper */
// *******************************************************************************
// 1.0 - Card Slider
// *******************************************************************************

const cardSliderElements = document.querySelectorAll('[data-slider="card-slider"]');

const cardSliderOptions = {
  slidesPerView: 1,
  spaceBetween: 0,
  watchOverflow: true,
  speed: 500,
  loop: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
};

cardSliderElements.forEach((el) => new Swiper(el, cardSliderOptions));
