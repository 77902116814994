// Ref - https://codepen.io/geoffgraham/pen/yLywVbW

const timer = document.querySelector('.base-timer');

if (timer) {
  const FULL_DASH_ARRAY = 283;

  const TIME_LIMIT = timer.getAttribute('data-duration');
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;
  let timerInterval = null;

  const onTimesUp = () => {
    clearInterval(timerInterval);
    timer.classList.add('time-over');
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  };

  const calculateTimeFraction = () => {
    const rawTimeFraction = timeLeft / TIME_LIMIT;
    return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
  };

  const setCircleDasharray = () => {
    const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
    document.querySelector('.base-timer__path-remaining').setAttribute('stroke-dasharray', circleDasharray);
  };

  const startTimer = () => {
    timerInterval = setInterval(() => {
      // eslint-disable-next-line no-multi-assign
      timePassed = timePassed += 1;
      timeLeft = TIME_LIMIT - timePassed;
      document.querySelector('.base-timer__label').innerHTML = formatTime(timeLeft);
      setCircleDasharray();

      if (timeLeft === 0) {
        onTimesUp();
      }
    }, 1000);
  };

  startTimer();
}
